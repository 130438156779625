<div class="DivPageContainer" id="divMainLandingContainer">
	<section class="SectionMain" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormHorizontalContainer" id="divMainLanding">
			<div class="DivForm2Column DivHeaderCenterContainer">
				<div class="DivHeaderContentContainer">
					<h1 class="FontSoftColor">Bisnis</h1>
					<h1 class="FontSoftColor">Syariah</h1>
					<p class="FontSoftColor">BRI Insurance menyediakan produk Asuransi Syariah yang dapat memberikan Anda solusi perlindungan berdasarkan prinsip Syariah di Indonesia.</p>
				</div>
				<!-- <button class="ButtonSubmit" (click)="goToSignIn();"><label class="FontSoftColor">Login</label></button> -->
			</div>

			<div class="DivForm2Column DivSocialMediaCenterContainer">
				<div class="DivSocialMedia">
					<img [src]="socialMedia.Path" *ngFor="let socialMedia of _arraySocialMedia" (click)="goToSNS(socialMedia.Link!)">
				</div>
			</div>
		</div>
	</section>

	<section class="SectionSecondary">
		<div class="DivFormVerticalContainer DivSiteContainer" id="divSiteLandingContainer">

			<h1>Sejarah</h1>
			<div class="DivFirstParagraph">
				<div class="DivFirstParagraphText">

					<p>BRI Insurance Syariah (BRINS Syariah) didirikan pada tanggal 21 Januari 2003 berdasarkan keputusan Menteri Keuangan Republik Indonesia Nomor : KEP-006/KM.6/2003. Pada awal didirikan, layanan BRINS Syariah diprioritaskan untuk memenuhi kebutuhan proteksi asuransi PT. Bank Rakyat Indonesia Syariah sebagai salah satu anak usaha BRI Group.</p>
					<p>Seiring perkembangan ekonomi syariah dan preferensi masyarakat Indonesia yang semakin banyak memilih-produk produk Syariah maka layanan Asuransi BRINS Syariah semakin luas dengan memberikan perlindungan dan layanan kepada Perbankan Syariah lainnya, Institusi – institusi baik swasta, BUMN, BUMD, dan masyarakat umum yang menjadikan Asuransi Syariah salah satu kebutuhan dalam proteksi asset yang dimiliki.</p>
					<p>Saat ini BRINS Syariah telah memiliki 2 (dua) Kantor Cabang Syariah yaitu di Jakarta & Aceh, 2 (dua) Kantor Perwakilan yaitu di Surabaya dan Bandung serta lebih dari 30 unit pemasaran syariah yang tersebar di seluruh Indonesia.</p>

				</div>

				<div class="DivFirstParagraphImage">
					<img src="../../../assets/photos/sakina/photo_landing_building.jpg">
				</div>

			</div>

			<div class="DivSecondParagraph">
				<h1>Sekilas Tentang Asuransi Syariah</h1>

				<p>Asuransi Syariah adalah usaha untuk saling melindungi dan saling menolong (taáwwun) di antara para pemegang polis (peserta), yang dilakukan melalui pengumpulan dan pengelolaan dana tabarru berdasarkan akad yang sesuai dengan prindin Syariah.</p>
	
				<p><b>Kontribusi</b> yang diberikan oleh peserta terdiri dari <b>(1) dana tabarru</b> yang akan digunakan untuk tolong menolong dalam hal terjadi risiko diantara peserta dan <b>(2) dana ujrah</b> yang akan digunakan oleh perusahaan asuransi (pengelola) dalam melakukan kegiatan operasional perusahaan.</p>
	
				<p>Berdasarkan hal tersebut maka Asuransi Syariah menggunakan <b>konsep sharing of risk</b> dimana jika ada peserta yang mengalami musibah makan peserta lain akan membantu melalui <i>pooling</i> dana tabarru sesuai dengan jenis risiko yang disepakati diawal. Akad yang digunakan dalam penutupan asuransi Syariah adalah <b>Akad Wakallah Bil ujrah</b>, yaitu Akad yang memberikan kuasa kepada Perusahaan Asuransi Syariah/Unit Syariah sebagai wakil peserta untuk mengelola Dana Tabarru', sesuai kuasa atau wewenang yang diberikan, dengan imbalan berupa ujrah (fee)</p>

			</div>
			<h1>Produk Unggulan</h1>

			<div class="DivHeaderProductLanding">
				<div class="DivHeaderProductDescription">
					<h2>Asuransi Kebakaran Syariah</h2>

					<div class="DivProductContent">
						<p>Produk Asuransi yang memberikan jaminan atas kerugian harta benda akibat kebakaran dan/atau beserta perluasannya yang tercantum dalam Polis. Dengan objek yang diasuransikan berupa gedung, bangunan rumah, kantor, hotel, pabrik, dan lain-lain.</p>
						<button class="ButtonSubmit" (click)="goToDetail('1')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
					</div>
				</div>

				<div class="DivHeaderProductImageDescription" [ngStyle]="getImageKebakaran()">
					<img src="../../../assets/gifs/transparent.gif">
				</div>
			</div>

			<div class="DivFormHorizontalContainer AlignStretch">
				<div class="DivProductLanding">
					<div class="DivProductImageDescription" [ngStyle]="getImageKendaraan()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi<br>Kendaraan<br>Syariah</h2>

						<div class="DivProductContent">
							<p>Produk asuransi yang memberikan perlindungan terhadap Kendaraan Bermotor atas risiko kerugian dan/atau kerusakan yang secara langsung disebabkan oleh risiko-risiko yang tercantum di polis.</p>

							<button class="ButtonSubmit" (click)="goToDetail('5')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>

				<div class="DivProductLanding">
					<div class="DivProductImageDescription" [ngStyle]="getImageKecelakaanDiri()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi<br>Kecelakaan Diri<br>Syariah</h2>

						<div class="DivProductContent">
							<p>Asuransi yang memberikan perlindungan atas risiko yang timbul akibat kecelakaan seperti kematian, cacat seluruh/sebagian tubuh dan biaya perawatan atau pengobatan yang diperlukan sebagai akibat dari kecelakaan tersebut.</p>
							<button class="ButtonSubmit" (click)="goToDetail('3')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>
			</div>

			<div class="DivFormHorizontalContainer AlignStretch">
				<div class="DivFooterProductLanding BorderBottomLeft35">
					<div class="DivProductImageDescription BorderBottomLeft35" [ngStyle]="getImageHajiDanUmroh()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi<br>Haji & Umroh</h2>

						<div class="DivProductContent">
							<p>Memberikan perlindungan atas kejadian yang tidak terduga yang bisa terjadi selama ibadah Haji atau Umroh. Diantaranya kecelakaan diri menyebabkan kematian, cacat dan dirawat dirumah sakit maka akan diberikan santunan biaya medis dan keterlambatan dan penundaan penerbangan serta kehilangan bagasi yang tercatat sesuai paket benefitnya.</p>
							<button class="ButtonSubmit" (click)="goToDetail('9')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>

				<div class="DivFooterProductLanding BorderBottomRight35">
					<div class="DivProductImageDescription" [ngStyle]="getImageUang()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi<br>Uang<br>Syariah</h2>

						<div class="DivProductContent">
							<p>Asuransi yang memberikan jaminan atas uang (uang tunai, logam mulia, emas murni, nota bank, cheque, wesel pos, dll) akibat risiko yang menimpa objek yang diasuransikan baik pada saat di perjalanan maupun di tempat penyimpanan (lemari besi/strong room, dan/atau chasier box).</p>
							<button class="ButtonSubmit" (click)="goToDetail('2')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>